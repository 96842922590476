import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import MasterLayout from "../components/masterlayout";
import Seo from "../components/seo";

import {
  header,
  container,
  main,
  social,
  links,
} from "./scss/index.module.scss";

const IndexHeader = () => {
  const data = useStaticQuery(graphql`
    query IndexSiteTitleQuery {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title, subTitle },
    },
  } = data;
  return (
    <header className={header}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
    </header>
  );
};

const IndexPage = () => (
  <MasterLayout
    className={container}
    mainClassName={main}
    header={<IndexHeader />}
  >
    <Seo
      title="Maroun Baydoun - Frontend Engineer and Mentor"
      withTitleTemplate={false}
    />
    <ul className={links}>
      <li>
        <Link to="/blog">Blog</Link>
      </li>
      <li>
        <Link to="/projects">Projects</Link>
      </li>
      <li>
        <OutboundLink
          href="https://dev.maroun-baydoun.com/"
          rel="me"
          eventlabel="dev.maroun-baydoun"
        >
          Open source
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.mentoring-club.com/the-mentors/maroun-baydoun/"
          target="_blank"
          rel="external noopener"
          eventlabel="mentoring-club"
        >
          Mentorship
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://theawesomegermanlanguage.com/"
          rel="me external noopener"
          target="_blank"
          eventlabel="The Awesome German Language"
        >
          German is awesome
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.instagram.com/marounbaydoun/"
          rel="me external noopener noreferrer"
          target="_blank"
          eventlabel="Instagram profile"
        >
          Photos
        </OutboundLink>
      </li>
    </ul>
    <p className={social}>
      Let's get in touch on{" "}
      <OutboundLink
        href="https://twitter.com/maroun_baydoun"
        rel="me external noopener noreferrer"
        target="_blank"
        eventlabel="Twitter profile"
      >
        Twitter
      </OutboundLink>
      ,{" "}
      <OutboundLink
        href="https://github.com/maroun-baydoun"
        rel="me external noopener noreferrer"
        target="_blank"
        eventlabel="Github profile"
      >
        Github
      </OutboundLink>{" "}
      or{" "}
      <OutboundLink
        href="https://www.linkedin.com/in/marounbaydoun"
        rel="me external noopener noreferrer"
        target="_blank"
        eventlabel="Linkedin profile"
      >
        Linkedin
      </OutboundLink>
      . Check my stories on{" "}
      <OutboundLink
        href="https://marouniverse.substack.com"
        rel="me external noopener noreferrer"
        target="_blank"
        eventlabel="Substack"
      >
        Substack
      </OutboundLink>
    </p>
  </MasterLayout>
);

export default IndexPage;
